(() => {
  if (window.customElements.get('pr-page-header')) return;

  class PrideraiserPageHeader extends HTMLElement {
    connectedCallback() {
      this.setAttribute('role', 'banner');
    }
  }

  window.customElements.define('pr-page-header', PrideraiserPageHeader);
})();
